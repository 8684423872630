import React from "react";

function Humor() {
    return (
        <main className="content">
            <h1 align="center">Разработчики, двигаемся! На поле тестировщики!</h1>
        </main>
    )
};

export default Humor;