import React, { useState } from "react";
import { Link } from "react-router-dom";

function MainGeneral() {
    const [imgSrc1, setImgSrc1] = useState("img/bug.svg");
    const [imgSrc2, setImgSrc2] = useState("img/bug.svg");
    const [imgSrc3, setImgSrc3] = useState("img/bug.svg");
    const [imgSrc4, setImgSrc4] = useState("img/bug.svg");
    const [imgSrc5, setImgSrc5] = useState("img/bug.svg");

    function handleClick(setter) {
        setter("img/blob.svg");

        setTimeout(function() {
            setter("img/bug.svg");
        }, 5000);
    }

    return (
        <main className="MainGeneral" style={{padding: "20px 0 350px 0"}}>
            <div className="window">
                <span className="TextWindow">
                    <h3>Добро пожаловать в QAFriends</h3>
                    <hr></hr>
                    <p>Это уютное сообщество для тестирования и разработки! Мы – это не просто группа тестировщиков; 
                        мы представляем собой единую команду, объединяющую разработчиков, тестировщиков, предпринимателей
                         и энтузиастов, направленных на создание программного обеспечения, которое призвано улучшить вашу повседневную жизнь</p>
                </span>
                <Link to="/aboutus"><button>Наша команда</button></Link>
                <img id = "test" className="LoupeL" src="img/Loupe.svg" onClick={() => handleClick(setImgSrc1)} />
                <img id="bug1" src={imgSrc1} />
            </div>

            <div className="window">
                <span className="TextWindow">
                    <h3>Наша миссия</h3>
                    <hr></hr>
                    <p>QAFriends – это не просто тестирование, это объединение профессионалов, готовых оказать поддержку на всех этапах 
                        разработки вашего веб-приложения. Наша цель – обеспечить бесперебойную работу вашего продукта через внимательное ручное тестирование.</p>
                </span>
                <Link to="/projects"><button>Наши проекты</button></Link>
                <img className="LoupeL" src="img/Loupe.svg" onClick={() => handleClick(setImgSrc2)} />
                <img id="bug2" src={imgSrc2} />
            </div>

            <div className="window">
                <span className="TextWindow">
                    <h3>Наши услуги</h3>
                    <hr></hr>
                    <p>Мы специализируемся на ручном тестировании веб-приложений. Независимо от того, являетесь ли вы стартапом, малым бизнесом или крупным 
                        предприятием, мы предоставим вам высококачественные тестовые услуги, полностью соответствующие уникальным потребностям вашего проекта.</p>
                </span>
                <img className="LoupeL" src="img/Loupe.svg" onClick={() => handleClick(setImgSrc3)} />
                <img id="bug3" src={imgSrc3} />
            </div>

            <div className="window">
                <span className="TextWindow">
                    <h3>Почему QAFriends?</h3>
                    <hr></hr>
                    <p><b>Сила в Сообществе:</b></p>
                    <p>Мы верим в коллективный интеллект. Наше сообщество объединяет опыт и таланты профессионалов, готовых поделиться 
                        знаниями и поддержать вас в решении любых тестовых задач. </p>
                    <p><b>Гибкий Подход:</b></p>
                    <p>Независимо от масштаба вашего проекта, мы разрабатываем индивидуальные стратегии тестирования, чтобы максимально 
                        соответствовать вашим потребностям и целям.</p>
                    <p><b>Качество на Первом Месте:</b></p>
                    <p>Наши тестировщики внимательно анализируют каждый аспект вашего приложения, чтобы обеспечить высший стандарт качества.</p>
                    <p><b>Партнерство на Всех Этапах:</b></p>
                    <p>Мы не просто тестируем, мы партнеры на пути к вашему успеху. Наша цель – сделать ваш продукт непревзойденным.</p>
                    <p><b>Присоединяйтесь к QAFriends</b></p>
                    <p>И обеспечьте своему веб-приложению надежное будущее. Мы здесь, чтобы сделать ваш путь в мире программного обеспечения 
                        более уверенным и успешным!</p>
                </span>
                <img className="LoupeL" src="img/Loupe.svg" onClick={() => handleClick(setImgSrc4)} />
                <img id="bug4" src={imgSrc4} />
            </div>

          

        </main> 
    );
}

export default MainGeneral;
