import React from "react";
import { Link } from "react-router-dom";

function AboutUs () {
    return (
        <main className="content">
            <h1 align="center">Лица и руки нашего проекта</h1>
            <div className="team_member lead">                
                <div className="lead">
                <img className="photo lead" src="/img/Oksana.jpg" alt="Оксана"/> <br />
                    Хрущева <br /> 
                    Оксана <br />
                    <p>(Team lead)</p>
                </div>
                <div className="lead">
                    <i>"Человек способен на все, что он может представить." - Омар Хайям</i>
                </div>
            </div>

            <hr></hr>

            <div className="team_member">
                
                <div className="member">
                <img className="photo" src="/img/Julia.jpg" alt="Юлия" /> <br />
                    Силина <br /> 
                    Юлия <br />
                    <p>(QA Engineer)</p>
                </div> 
                
            </div>

            <hr></hr>

            <div className="team_member">
                
                <div className="member">
                <img className="photo" src="/img/Alexandr.jpg" alt="Александр" /> <br />
                    Катречко <br /> 
                    Александр <br />
                    <p>(QA Engineer)</p>
                </div> 
                <div className="aboutme">
                    <i>"Люблю интересные задачи, узнавать новое и учиться"</i>
                </div>
            </div>
            <hr></hr>

            <div className="team_member">
                
                <div className="member">
                <img className="photo" src="/img/Natalya.jpg" alt="Наталья" /> <br />
                    Егорова <br /> 
                    Наталья <br />
                    <p>(QA Engineer)</p>
                </div> 
                <div className="aboutme">
                    <i>"Уважаю компании, которые стремятся улучшать жизнь
                         людей с помощью высококачественных программных продуктов 
                         и услуг. Это очень важно для меня, поскольку я тоже увлечена обеспечением их качества."</i>
                </div>
            </div>
            <hr></hr>
        </main>

    )
};

export default AboutUs