import React from "react";

function Footer() {
    return (

        <footer name="footer" className="footer">
            <div className="contacts">
                <ul> Контакты: <p></p>
                    <li>
                        <a href="tel:+79832656580">
                            <img className="contacts_element" src="img/phone.png"></img>
                            +7-983-265-65-80
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/QAFriends">
                            <img className="contacts_element" src="img/telegram.png"></img>
                            QAFriends
                        </a>
                    </li>
                    <li>
                        <a href="mailto:qaf@qa-friends.pro">
                            <img className="contacts_element" src="img/e-mail.png"></img>
                            qaf@qa-friends.pro
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
};

export default Footer;