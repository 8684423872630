import React from "react";
import { Link } from "react-router-dom";



function Projects() {
    return (
        <main className="projects">
            <h1>Наши проекты</h1>
            <Link to="/doky"><img src="img/LogoDoky.png"></img></Link>
        </main>
    )
};

export default Projects;