import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Vivus from "vivus";


function Count({ end }) {
    const [count, setCount] = useState(0);
  
    useEffect(() => {
      let start = 0;
      if (start < end) {
        let timerId = setInterval(() => {
          start += 1;
          setCount(start);
          if (start === end) clearInterval(timerId);
        }, 4);
      }
    }, [end]);
  
    return (
        <div>{count}</div>
    )
  } 

function PageProject({link_site, img_logo, description, testTypes, status_qa, team, tasks, solutions, results, testers, bugs, tests}) {
    useEffect(() => {
        const checkMarks = document.querySelectorAll('.CheckMark');
        let delay = 300;
        const duration = 100;
        checkMarks.forEach((mark, index) => {
            const instance = new Vivus(mark, {
                duration: duration,
                type: 'oneByOne',
                animTimingFunction: Vivus.EASE
            });
            setTimeout(() => {
                instance.play();
            }, index * delay);
            delay += duration;
        });
    }, []);

    return (
    <main className="project_info">
        <div  className="name_project">
                
                <Link to={link_site} target="_blank" rel="noopener noreferrer" style={{margin: "auto"}}><img src={img_logo}></img></Link>
                
        </div>
        <div className="name_project1">
        {testTypes.map((testType, index) =>
                    <div className="types_tests" key={index}>
                        <object className="CheckMark" type="image/svg+xml" data="img/CheckMark.svg"></object> 
                        {testType}
                    </div>
                )}
        </div>
        
        <h4>Описание</h4>
        
            <p><i>{description}</i></p>
        <hr></hr>

        <h4>Статус</h4>
            <p><i>{status_qa}</i></p>
        <hr></hr>
        <h4>Команда проекта</h4>
            <ul>
                {team.map((person, index) =>
                <li key={index}><i>{person}</i></li>)}
            </ul>
        
            <hr></hr>

        <h4>Задачи</h4>
            <p><i>{tasks}</i></p>

            <hr></hr>

        <h4>Решение</h4>
            {solutions}
            <hr></hr>

        <h4>Результат</h4>
            <p><i>{results}</i></p>

        <hr></hr>

        <div className="counter">
            <div className="MembersCount"><Count end={testers} /></div>
            <div className="BugsCount"><Count end={bugs} /></div>
            <div className="ChecksCount"><Count end={tests} /></div>
        </div>


    </main>
    )
}

export default PageProject;