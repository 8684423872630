import React from "react";

function Blog() {
    return (
        <main className="content">
            <h1 align="center">Я - тестировщик, этим все сказано!</h1>
        </main>
    )
};

export default Blog;