import React from "react";

function UseFul() {
    return (
        <main className="useful">
            <h1>Полезности для тестировщика</h1>
        </main>
    )
};

export default UseFul;