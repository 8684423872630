import React from "react";


function OksanaKhrushcheva() {
    
    return (
        <main className="content_member">
            <div className="member_info">
                <div className="photo_membe">
                    <img className="photo" src="/img/Oksana.jpg" alt="Оксана"/>
                    <p className="member">Хрущева</p>
                    <p className="member">Оксана</p>
                    <p className="status"><i>(Team lead)</i></p>
                </div>
                                   
            </div>
        </main>
    )
}

export default OksanaKhrushcheva