import './App.css';
import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import MainGeneral from "./MainGeneral";
import AboutUs from "./AboutUs";
import Blog from "./Blog";
import Humor from "./Humor";
import UseFul from "./UseFul";
import Projects from "./Projects";
import Doky from './doky';
import OksanaKhrushcheva from "./OksanaKhrushcheva"


function App() {
  
  return (
    <Router>
        <Header />
        <div className='container'>
        <Routes>
          <Route path='/' element = {<MainGeneral />}></Route>
          <Route path='/aboutus' element = {<AboutUs />}></Route>
          <Route path='/projects' element = {<Projects />}></Route>
          <Route path='/blog' element = {<Blog />}></Route>
          <Route path='/humor' element = {<Humor />}></Route>
          <Route path='/useful' element = {<UseFul />}></Route>
          <Route path='/doky' element = {<Doky />}></Route>
          <Route path='/oksanakhrushcheva' element = {<OksanaKhrushcheva />}></Route>
        </Routes>
        </div>
          <Footer />    
    </Router>
  );
}


export default App

