import PageProject from "./PageProject.js"

function Solution() {
    return (

        <p><i>Благодаря верному распределению ресурсов, удалось соблюсти сроки, поставленные заказчиком. 
        В рамках тестирования было проведено:</i>
    <ul>
        <li>Анализ требований</li>
        <li>Составление и согласование тест-плана</li>
        <li>Написание тестовой документации (тест-кейсы, чек-листы)</li>
        <li>Проведение различных видов тестирования, включая:
            <ul className="InList">
                <li className="InList">Тестирование API</li>
                <li className="InList">Функциональное тестирование</li>
                <li className="InList">Тестирование вёрстки</li>
                <li className="InList">Кроссбраузерное тестирование</li>
                <li className="InList">Регрессионное тестирование</li>
                <li className="InList">Тестирование в режиме HotFix</li>
            </ul>
        </li>
        <li>Создание отчетов о результатах тестирования</li>
    </ul>
    </p>

    )
}

export default function Doky() {
    
    return (
        <PageProject 
        link_site="https://doky.pro/"
        img_logo="img/LogoDoky.png"
        description="Веб-приложение 'Шаблонизатор' представляет собой инструмент, который значительно упрощает работу с разнообразными типами документов. 
        Оно обеспечивает возможность быстрого поиска готовых шаблонов в базе данных, а также позволяет вносить корректировки, создавать, заполнять
         и форматировать документы различных видов. Применение данного приложения способствует повышению единообразия и согласованности документации, 
         минимизации ошибок и увеличению эффективности работы"
        testTypes={["Тестирование требований", "Функциональное тестирование", "Тестирование API", "Регрессионное тестирование", "Тестирование GUI", "Smoke тестирование"]}
        status_qa="QA сопровождение продолжается"
        team={["Хрущева Оксана", "Егорова Наталья", "Силина Юлия", "Манвелян Григорий"]}
        tasks='Перед командой тестирования стояла задача помочь оценить работоспособность разрабатываемого MVP проекта, выявить ошибки в приложении. Основная 
        сложность в работе заключалась в частоте внеплановых изменений требований к ПО, в задержке в согласовании документов с командой.'
        solutions={<Solution />}
        results='В течение 2 месяцев протестировано 2 релиза,  все сроки тестирования были соблюдены. Во время работы были внесены предложения по улучшению работы 
        приложения, которые были приняты и реализованы заказчиком'
        testers={5}
        bugs={241}
        tests={2064}
        />
    )
};