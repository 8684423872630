import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Vivus from "vivus";

function Header() {
  const [isVisible, setIsVisible] = useState(false);

  // Показываем кнопку при прокрутке страницы
  const toggleVisibility = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Перемещаемся в начало страницы при нажатии на кнопку
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    new Vivus('logo', {
        duration: 500,
        delay: 0,
        type: 'oneByOne',
        animTimingFunction: Vivus.EASE

    })
}, []);


  return (
    <header className="header">
      <div className='container'>
      <a href="/" className="svg"><object id="logo" type="image/svg+xml" data="img/QAF.svg"></object></a>
        <nav className="button_head">
          <Link className="a_head" to="/aboutus">О нас</Link>
          <Link className="a_head" to="/projects">Наши проекты</Link>
          <Link className="a_head" to="/useful">Полезное</Link>
        </nav>
        {isVisible && 
        <button id="myBtn" onClick={scrollToTop}>
          <img src="img/Upper.svg" alt="Вверх"></img>
        </button>}
      </div>
    </header>
  )
}

export default Header;
